@tailwind base;
@tailwind components;
@tailwind utilities;

.center {
   position: fixed;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}

.slideshow {
   margin: 0 auto;
   overflow: hidden;
}

.slideshowSlider {
   /* white-space: nowrap; */
   transition: ease 1000ms;
}

.swiper-pagination-bullets {
   position: relative;
   /* margin-bottom: -100px; */
}

.home-banner {
   height: 700px;
}

.banner {
   height: 550px;
}

@media (max-width: 480px) {
   .banner {
      height: 250px;
   }

   .home-banner {
      height: 650px;
   }
}

/* pipeline table */

#pipeline-table {
	padding: 25px 0 100px;
}

.table-header {
	font-family: 'Gill Sans', 'Gill Sans MT', Calibri, sans-serif;
	font-weight: bold;
	font-size: 20px;
	text-align: center !important;
	padding-top: 30px;
	color: rgb(128,	130,	133);
}

.header-left {
	position: absolute;
	top: 0;
}

.table-content {
	display: flex;
	border-top: 1px solid #e8e8e8;
	position: relative;
}
.table-content:after {
	content: '';
	position: absolute;
	top: 80px;
	background: #e8e8e8;
	height: 2px;
	width: 100%;
}

.left-side {
	position: relative;
	width: 66%;
}

.bars {
	margin-top: 105px;
}

.indication {
	width: 250px;
	text-align: right !important;
}

.discovery {
	right: 25%;
	width: 36%;
}

.scientific {
	position: absolute;
	right: 22%;
	width: 25%;
}

.development {
	height: 100%;
	/* background: #eeeeee; */
	right: 4%;
	width: 20%;
}

.character {
	right: -10%;
}

.table-bars {
	margin: 30px 0;
	border-radius: 20px;
	position: relative;
	overflow: hidden;
	width: 100%;
}

.table-bars span {
	margin-right: 30px;
	width: 250px;
	flex-shrink: 0;
	font-size: 26px;
	line-height: 26px;
	text-align: right;
	color: rgb(128,	130,	133);
}

.bar-box {
	display: flex !important;
	align-items: center;
}

.table-bars div .progress {
	background-color: #0071b9;
	border-radius: 20px;
	border-right: 13px solid rgb(159,	219, 244);
	-webkit-animation: progressBar 2.5s ease-in-out;
	-webkit-animation-fill-mode:both;
	-moz-animation: progressBar 2.5s ease-in-out;
	-moz-animation-fill-mode:both;
	height: 20px;
}

@-webkit-keyframes progressBar {
	0% { width: 0; }
	100% { width: 100%; }
}

@-moz-keyframes progressBar {
	0% { width: 0; }
	100% { width: 100%; }
}

.table-bars .bpd {
	width: 100%;
}
.table-bars .influenza {
	width: 74%;
}
.table-bars .rsv {
	width: 72%;
}
.table-bars .covid {
	width: 88%;
}
.table-bars .other {
	width: 55%;
}
.table-bars .non-respiratory {
	width: 55%;
}

.right-side {
	width: 33%;
	display: flex;
	justify-content: space-between;
}

.right-side .table-header {
	width:100%;
}

@media screen and (max-width:1180px) {

	.indication,
	.table-bars span {
		width: 17vw;
	}

	.table-bars span {
		font-size: 2rem;
		line-height: 20px;
	}
}

@media screen and (min-width:601px) and (max-width:800px) {

	.table-header {
		font-size: 14px;
	}

	.table-bars {
		margin: 20px 0;
	}
	.table-bars div .progress {
		height: 15px;
	}

	.table-bars span {
		font-size: 13px;
		line-height: 13px;
	}
}

@media screen and (min-width:801px) and (max-width:900px) {

	.table-header {
		font-size: 16px;
	}

	.table-bars {
		margin: 20px 0;
	}
	.table-bars div .progress {
		height: 17px;
	}

	.table-bars span {
		font-size: 17px;
		line-height: 17px;
	}
}

@media screen and (max-width:600px) {

	#pipeline-table {
		padding: 25px 0 0;
	}
	.table-content:after {
		top: 65px;
	}

	.table-header {
		font-size: 10px;
		line-height: 15px;
		padding-top: 20px;
	}

	.bars {
		margin-top: 80px;
	}

	.table-bars {
		margin: 10px 0;
	}
	.table-bars div .progress {
		height: 8px;
	}

	.table-bars span {
		font-size: 8px;
		line-height: 10px;
		margin-right: 15px;
	}

	.table-bars div .progress {
		border-right: 6px solid rgb(159, 219, 244);
	}
}

/* pipeline video */

/* .footer {
	background-color: red;
	position: sticky;
	bottom: 0;
	right: 0;
	left: 0;
	transform: translateX(-25%);
	border-radius: 50% 50% 0 0;
}
 */
